<template>
  <div id="updateImageModal" class="create-post" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">{{ title }}</h3>
        <button class="updateImageClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                type="button" uk-close
                uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        <div id="update-thumbnail-inner" data-simplebar style="height: calc(100vh - 230px)">
          <drop-zone type="image" :imageProp="image" v-model:objectIdProp="image_id" v-on:uploadDone="handleUploadDone"/>

          <div class="grid md:grid-cols-6 grid-cols-2 gap-3 mt-5">
            <div :uk-tooltip="'title: ' + file.title" v-for="file in images.data" :key="'file' + file.id"
                 v-on:click="setThumbnail(file)" class="cursor-pointer">
              <div
                  class="bg-green-400 max-w-full lg:h-20 h-20 rounded-lg relative overflow-hidden shadow uk-transition-toggle">
                <img :src="file.url" class="w-full h-full absolute object-cover inset-0">
                <div
                    class="-bottom-12 absolute bg-gradient-to-b from-transparent h-1/2 to-gray-800 uk-transition-slide-bottom-small w-full"></div>
              </div>
            </div>
          </div>
          <div class="mt-6">
            <v-pagination
                class="justify-content-center"
                v-if="images.paginatorInfo.lastPage > 1"
                v-model="images.paginatorInfo.currentPage"
                :pages="images.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadImages"
            />
          </div>
        </div>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="updateImage"
           class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Cập nhật</a>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../core/services/api.service";
import DropZone from "./DropZone";

export default {
  name: "UpdateImageModal",
  props: {
    title: String,
    updateType: String,
    type: String,
    id: String
  },
  components: {DropZone},
  data() {
    return {
      image_id: null,
      image: null,
      images: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
    }
  },
  methods: {
    updateImage() {
      let query = `mutation($id: ID!, $type: String!, $image_id: ID!) {
        ${this.updateType}(id: $id, type: $type, image_id: $image_id)
      }`;

      ApiService.graphql(query, {id: this.id, type: this.type, image_id: this.image_id})
          .then(({data}) => {
            if (data.data && data.data[this.updateType]) {
              this.$emit("updateImageDone");
              window.$(".updateImageClose").click();
              this.$toast.success("Cập nhật thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    setThumbnail(file) {
      this.image = file.url;
      this.image_id = file.id;
    },
    handleUploadDone() {
      this.loadImages(true);
    },
    loadImages(selectFirst) {
      let query = `query($page: Int) {
        me {
          images(first: 24, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              id
              title
              url
              created_at
            }
            paginatorInfo {
              currentPage
              total
              lastPage
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.images.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.me && data.data.me.images) {
              this.images = data.data.me.images;
              if (selectFirst === true) {
                this.setThumbnail(this.images.data[0]);
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
  },
  mounted() {
    this.loadImages();
  }
}
</script>
