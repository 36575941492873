<template>
  <div class="flex items-center space-x-4 py-3 hover:bg-gray-100 rounded-md my-2 px-2">
    <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
      <router-link :title="document.title" class="text-lg font-semibold" :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug, prefix: prefix}}">
        <img :alt="document.title" :src="document.thumbnail ? document.thumbnail.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
      </router-link>
    </div>
    <div class="flex-1">
      <router-link :title="document.title" class="font-semibold" :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug, prefix: prefix}}">
        {{ document.title }}
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "RelatedDocumentItem",
  props: {
    document: {
      type: Object,
      default() {
        return {
          ids: "",
          title: "",
          slug: ""
        };
      }
    }
  }
}
</script>
